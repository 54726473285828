import { Button } from "@mui/material"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import MainLayout from "../Layouts/MainLayout"
import QuestionAnswerBox from "../Components/QuestionAnswerBox"
import BackForwardButtonBox from "../Components/BackForwardButtonBox"
import Question from "../Components/Question"
/* import AnswerSlider from "../Components/AnswerSlider" */
import AnswerSelect from "../Components/AnswerSelect"

import { AnswersType } from "../Models/AnswersType"
import { ActivePage } from "../Models/ActivePageType"
import { QuestionTypes } from "../Models/QuestionTypes"
import { updateAnswer } from "../Utilities/SetNewAnswer"
import { AnswerNumber } from "../Models/AnswerNumberType"
import { evaluationStageType } from "../Models/EvaluationStageType"

import { updateCollectedAnswersCopy } from "../Utilities/UpdateCollectedAnswersCopy"

import { determineQuestionsArr } from "../Utilities/EvaluationStageVariables"
import React from "react"

interface EvaluationQuestionProps {
    activePage: ActivePage;
    collectedAnswers: AnswersType;
    setPageToStart: () => void;
    setPageToQuestionX: (qNum: number) => void;
    setPageToEnd: () => void;
    setCollectedAnswers: React.Dispatch<React.SetStateAction<AnswersType>>;
    setQuestionType: React.Dispatch<React.SetStateAction<QuestionTypes>>;
    evaluationStage: evaluationStageType;
    setCollectedAnswersCopy: React.Dispatch<React.SetStateAction<AnswersType>>;
    collectedAnswersCopy: AnswersType;
}

//Page for displaying the current active question, and related answer selections.
const EvaluationQuestion: React.FC<EvaluationQuestionProps> = ({
    activePage,
    collectedAnswers,
    setPageToStart,
    setPageToQuestionX,
    setPageToEnd,
    setCollectedAnswers,
    evaluationStage,
    setCollectedAnswersCopy,
    collectedAnswersCopy

}) => {
    let questionToRender: number = 0
    let answersToRender: AnswerNumber = "answer1";

    //This Variable determines if the button to move foward will be visible or not. Visibility is dependent on user selecting answer. 
    let makeVisible: boolean = false

    let pageForward: () => void = () => { }
    let pageBackward: () => void = () => { }

    const questionsArr = determineQuestionsArr(evaluationStage);

    //Retrieves questions from questionsArr data, and generates them.
    //This function is kept here, because it has such a large prop dependency.
    const generateQuestions = () => {
        questionsArr.forEach((question, index) => {
            const qNum = index + 1;
            const answerKey = `answer${qNum}` as AnswerNumber;
            const nextQuestion = qNum + 1
            const prevQuestion = qNum - 1

            switch (activePage) {
                case `question${qNum}`:
                    questionToRender = qNum
                    answersToRender = answerKey

                    const removeAnswers = { [`answer${qNum}`]: null, [`answer${qNum - 1}`]: null, };

                    const isFirstQuestion = qNum === 1;
                    const isLastQuestion = qNum === questionsArr.length;
                    const isOnlyQuestion = questionsArr.length === 1;

                    if (isFirstQuestion) {
                        pageForward = () => {
                            if (isOnlyQuestion) {
                                updateCollectedAnswersCopy(collectedAnswers, setCollectedAnswersCopy);
                                setPageToEnd();
                            } else {
                                setPageToQuestionX(nextQuestion);
                            }
                        }
                        pageBackward = () => {
                            setCollectedAnswers({});
                            setPageToStart();
                        }
                    } else if (isLastQuestion) {
                        pageForward = () => { setPageToEnd() };
                        pageBackward = () => {
                            updateAnswer(removeAnswers, setCollectedAnswers);
                            setPageToQuestionX(prevQuestion);
                        }
                    } else {
                        pageForward = () => setPageToQuestionX(nextQuestion);
                        pageBackward = () => {
                            updateAnswer(removeAnswers, setCollectedAnswers);
                            setPageToQuestionX(prevQuestion);
                        }
                    }

                    if (evaluationStage === "full") {
                        makeVisible = collectedAnswers[answerKey as keyof AnswersType] !== null;
                    } else if (evaluationStage === "before" || "after") {
                        makeVisible = Object.keys(collectedAnswers).length !== 0;
                    }

                    break;
            }
        })
    }

    generateQuestions();

    //PROPS
    const backForwardButtonProps = {
        makeVisible,
        pageForward,
        pageBackward,
        activePage,
        collectedAnswersCopy,
        evaluationStage,
        setCollectedAnswers,
        setPageToStart,
        collectedAnswers
    };
    const questionProps = {
        question: questionToRender,
        evaluationStage
    };
    const answerSelectProps = {
        answerNumber: answersToRender,
        collectedAnswers,
        setCollectedAnswers,
        evaluationStage
    };

    return (
        <>
            <BackForwardButtonBox {...backForwardButtonProps} />
            <QuestionAnswerBox >
                <Question {...questionProps} />
                <AnswerSelect {...answerSelectProps} />
            </QuestionAnswerBox>
            <Button
                onClick={() => {
                    pageForward()
                }}
                sx={{
                    alignSelf: "flex-end",
                    visibility: makeVisible === false ? "hidden" : "visible",
                    px: '1.25rem',
                    py: '0.66rem',
                    borderRadius: "20px",
                }}
                variant="contained"
                color="secondary"
            >
                FORTSÄTT
                <ArrowForwardIosIcon sx={{ fontSize: '2.75rem', }} />
            </Button >
        </>
    )
}

export default EvaluationQuestion
