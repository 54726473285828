import { QuestionType, questionsObj } from "../Data/questions"
import { evaluationStageType } from "../Models/EvaluationStageType"

//These functions are for handling differences and handle logic based on,
//what type of evaluation is currently active.

export const determineQuestionsArr = (evaluationStage: evaluationStageType) => {
    let questionsArr: QuestionType[]

    if (evaluationStage === "full") {
        questionsArr = questionsObj.full
    } else {
        questionsArr = questionsObj.beforeAfter
    }

    return questionsArr
}

export const startButtonText = (evaluationStage: evaluationStageType) => {
    let buttonText = ""

    if (evaluationStage === "before") {
        buttonText = "STARTA NY FÖREMÄTNING"
    } else if (evaluationStage === "after") {
        buttonText = "STARTA NY EFTERMÄTNING"
    } else if (evaluationStage === "full") {
        buttonText = "STARTA NY UTVÄRDERING"
    }

    return buttonText
}
