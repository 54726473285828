import { AnswerNumber } from "../Models/AnswerNumberType"
import { AnswersType } from "../Models/AnswersType"
import { QuestionTypes } from "../Models/QuestionTypes"
import styles from "../Styles/SelectHighlighting.module.css"

//These make styles changes to the AnswerSelect Cards, based on which one is selected.
export const highlightSelect = (num: number, collectedAnswers: AnswersType, answerNumber: AnswerNumber) =>
    collectedAnswers[answerNumber as keyof AnswersType]?.value === num ? styles.selectIsActive : ""

export const diminishSelect = (num: number, collectedAnswers: AnswersType, answerNumber: AnswerNumber) => {
    let setStyle
    if (Object.keys(collectedAnswers).length !== 0) {
        setStyle = collectedAnswers[answerNumber as keyof AnswersType] === null || collectedAnswers[answerNumber as keyof AnswersType]?.value === num ? "" : styles.selectIsNotActive
    }
    return setStyle
}

/* export const highlightDiminishChooseType = (questionType: QuestionTypes, cardType: QuestionTypes) => {
    const highlightSelect = questionType === "select" ? styles.selectIsActive : ""
    const highlightSlider = questionType === "slider" ? styles.selectIsActive : ""
    const diminishSelect = questionType !== null && questionType !== "select" ? styles.selectIsNotActive : ""
    const diminishSlider = questionType !== null && questionType !== "slider" ? styles.selectIsNotActive : ""

    return cardType === "select" ? `${highlightSelect}${diminishSelect}` : `${highlightSlider}${diminishSlider}`
}
 */
