import React from 'react'
import { useEffect } from 'react';

import { Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material"

import { useNavigate } from "react-router-dom";
import { ROUTES } from '../../../utils/api'
import { AnswersType } from '../Models/AnswersType';

interface ExitModalProps {
    endCurrentClicked: boolean;
    setEndCurrentClicked: (exit: boolean) => void;
    setCollectedAnswers: React.Dispatch<React.SetStateAction<AnswersType>>;
    setPageToStart: () => void;
    collectedAnswersCopy: AnswersType
}

//This modal is for confirming abortion of current evaluation, and exiting back to main app.
const EndCurrentModal: React.FC<ExitModalProps> = ({ endCurrentClicked, setEndCurrentClicked, setCollectedAnswers, setPageToStart, collectedAnswersCopy }) => {
    //Modal Logic
    const [openModal, setOpenModal] = React.useState(false);
    const handleCloseModal = () => {
        setOpenModal(false);
        setEndCurrentClicked(false);
    };
    useEffect(() => {
        if (endCurrentClicked) {
            setOpenModal(true);
        }
    }, [endCurrentClicked])

    const handleEnd = () => {
        setCollectedAnswers({});
        setPageToStart();

        alert("Current answer was reset."); //Temp for demo purposes
    }

    const navigate = useNavigate();

    const currentQuestionIndexValue = Object.keys(collectedAnswersCopy).length + 1

    return (
        <Dialog
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={false}
            PaperProps={{ sx: { padding: "3rem" } }}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h1"
                    sx={{
                        border: "solid 3px #41b771",
                        borderRadius: "40px",
                        py: 8,
                        px: 5,
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    {`VILL DU AVBRYTA UTVÄRDERING ( ${currentQuestionIndexValue} )?`}
                </Typography>
            </DialogTitle>
            <DialogActions sx={{ alignItems: "center", justifyContent: "center", mt: "1rem" }}>
                <Button onClick={handleCloseModal} variant="outlined" color="secondary" sx={{ mx: "3rem", fontSize: "2.5rem" }}>NEJ, FORTSÄTT</Button>
                <Button onClick={handleEnd} variant="contained" color="error" sx={{ mx: "3rem", fontSize: "2.5rem" }}>JA, AVSLUTA</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EndCurrentModal
