import { Box } from "@mui/material"
import { WithChildren } from "../Models/WithChildren"

import styles from "../Styles/borderBox.module.css"

import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen"

const MainLayout: React.FC<WithChildren> = ({ children }) => {
    const isSmallScreen = useIsSmallScreen();
    const height = isSmallScreen ? undefined : "100dvh";

    return (
        <Box
            className={styles.borderBox}
            sx={{
                height: height,
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 5,
            }}
        >
            {children}
        </Box>
    )
}

export default MainLayout