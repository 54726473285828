import { Box, Button, Card, CardMedia } from "@mui/material"
import { flexCenterR, flexCenterC } from "../Styles/EvaluationToolTheme";

interface StartContentBoxProps {
    imageA: string;
    /*     imageB: string; */
    imageAltA: string;
    /*     imageAltB: string; */
    buttonText: string;
    buttonAction: () => void;
}

const cardStyles = {
    height: "100%",
    mx: 8,
    boxShadow: 'none',
}

const cardMediaStyles = {
    height: "100%",
    width: "auto",
    objectFit: "cover",
}

const StartContentBox: React.FC<StartContentBoxProps> = ({
    imageA,
    /*     imageB, */
    imageAltA,
    /*     imageAltB, */
    buttonText,
    buttonAction,
}) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                ...flexCenterC,
                mb: "3rem",
            }}
        >
            <Box sx={{
                margin: "auto",
                width: "100%",
                ...flexCenterC,
            }}>
                <Card
                    sx={{
                        ...cardStyles
                    }}
                >
                    <CardMedia
                        sx={{
                            ...cardMediaStyles
                        }}
                        component="img"
                        alt={imageAltA}
                        image={imageA}
                    />
                </Card>

                {/*                 <Card
                    sx={{
                        ...cardStyles
                    }}
                >
                    <CardMedia
                        sx={{
                            ...cardMediaStyles
                        }}
                        component="img"
                        alt={imageAltB}
                        image={imageB}
                    />
                </Card> */}
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        mt: 4,
                        padding: "1.25rem 0.66rem",
                        borderRadius: "20px",
                    }}
                    onClick={buttonAction}
                >
                    {buttonText}
                </Button>
            </Box>

        </Box >
    )
}

export default StartContentBox
