import { AnswersType } from "../Models/AnswersType"

//Helper function handling partial updates of the setCollectedAnswers state.
export const updateAnswer = (updates: Partial<AnswersType>, setCollectedAnswers: React.Dispatch<React.SetStateAction<AnswersType>>) => {
    const filteredUpdates = Object.fromEntries(Object.entries(updates).filter(([_, value]) => value !== undefined))

    setCollectedAnswers((prevState) => ({
        ...prevState,
        ...filteredUpdates
    }))
}
