import { AnswersType, AnswerDataType } from "../Models/AnswersType"

//This makes takes the current collectedAnswers and adds it to a new state.
//This is for storing the answers collected in evaluationStages that allows looping of questionaire.
export const updateCollectedAnswersCopy = (collectedAnswers: AnswersType, setCollectedAnswersCopy: React.Dispatch<React.SetStateAction<AnswersType>>) => {
    const answerToPush = Object.values(collectedAnswers)[0] as AnswerDataType

    setCollectedAnswersCopy((prevState) => {
        const answerNumber = Object.keys(prevState).length + 1
        const answer = `answer${answerNumber}`
        const newState = { ...prevState, [answer]: answerToPush }

        return newState
    })
}

export const deleteLastAnswer = (setCollectedAnswersCopy: React.Dispatch<React.SetStateAction<AnswersType>>) => {
    setCollectedAnswersCopy((prevState) => {
        const keys = Object.keys(prevState)
        const lastKey = keys[keys.length - 1] as keyof AnswersType
        const newState = { ...prevState }
        delete newState[lastKey]
        return newState
    })
}
