import { useRef, useEffect } from "react"

import React from "react"

import { Box, Button, Card, CardMedia, Typography } from "@mui/material"
import { flexCenterR, flexCenterC, flexSpaceR } from "../Styles/EvaluationToolTheme"
import { evaluationStageType } from "../Models/EvaluationStageType"
import { AnswersType } from "../Models/AnswersType"

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';

import EndModal from "../Components/EndModal"

import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen"
import { deleteLastAnswer } from "../Utilities/UpdateCollectedAnswersCopy"

interface EndContentBoxProps {
    imageA: string
    /*     imageB: string; */
    imageAltA: string
    /*     imageAltB: string; */
    buttonText: string;
    buttonAction: () => void;
    evaluationStage: evaluationStageType;
    setPageToStart: () => void;
    collectedAnswers: AnswersType;
    setCollectedAnswersCopy: React.Dispatch<React.SetStateAction<AnswersType>>;
    collectedAnswersCopy: AnswersType;
    setCollectedAnswers: React.Dispatch<React.SetStateAction<AnswersType>>;
    setPageToQuestionX: () => void;
}

const cardStyles = {
    height: "100%",
    mx: 8,
    boxShadow: "none",
}

const cardMediaStyles = {
    height: "100%",
    width: "auto",
    objectFit: "cover",
}

const EndContentBox: React.FC<EndContentBoxProps> = ({
    imageA,
    /*     imageB, */
    imageAltA,
    /*     imageAltB, */
    buttonText,
    buttonAction,
    evaluationStage,
    setPageToStart,
    collectedAnswers,
    setCollectedAnswersCopy,
    collectedAnswersCopy,
    setCollectedAnswers,
    setPageToQuestionX
}) => {
    const isSmallScreen = useIsSmallScreen()

    //Handles Page redirection together with handleForward(),
    //To ensure redirection does not happen before state update.
    //Look for better solution?
    const hasUpdated = useRef(false)
    useEffect(() => {
        if (hasUpdated.current === true) {
            setPageToStart();
        }
        hasUpdated.current = false
    }, [collectedAnswers])

    //Handles forward Logic
    const isProcessing = useRef(false)
    const handleForward = () => {
        if (isProcessing.current) return
        isProcessing.current = true

        setCollectedAnswers({})
        hasUpdated.current = true
    }

    const handleChangeAnswer = () => {
        deleteLastAnswer(setCollectedAnswersCopy)
        setPageToQuestionX();
    }

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    ...flexCenterC,
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        ...flexCenterC,
                        margin: "auto",
                        ...(isSmallScreen && { mt: 8 }),
                    }}
                >
                    <Card
                        sx={{
                            ...cardStyles, mb: "2rem",
                        }}
                    >
                        <CardMedia
                            sx={{
                                ...cardMediaStyles,
                            }}
                            component="img"
                            alt={imageAltA}
                            image={imageA}
                        />
                    </Card>
                    <Typography variant="h1" color="initial" sx={{ fontSize: "2.5rem" }}>TACK FÖR DITT SVAR!</Typography>

                    {/*                 <Card
                    sx={{
                        ...cardStyles
                    }}
                >
                    <CardMedia
                        sx={{
                            ...cardMediaStyles
                        }}
                        component="img"
                        alt={imageAltB}
                        image={imageB}
                    />
                </Card> */}
                </Box>

                {
                    evaluationStage === "full" ? (
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                padding: "1.25rem 0.66rem",
                                borderRadius: "20px",
                            }}
                            onClick={() => { console.log("logic") }}
                        >
                            {buttonText}
                        </Button>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    ...(isSmallScreen ? { ...flexCenterC, mt: 4, height: "25%" } : { ...flexCenterR, mt: 8, width: "75%", justifyContent: "space-evenly" }),
                                }}
                            >
                            </Box>

                        </>
                    )
                }
            </Box >
            < Box sx={{ width: "100%", ...flexSpaceR, }}>
                <Button
                    onClick={handleChangeAnswer}
                    variant="outlined"
                    color="secondary"
                    sx={{
                        borderRadius: "20px",
                        px: "1.25rem",
                        py: "0.66rem",
                    }}
                >
                    <ArrowBackIosIcon sx={{ fontSize: '2.75rem', }} />Ändra Svar
                </Button>
                <Button
                    onClick={() => {
                        handleForward()
                    }}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px",
                        px: "1.25rem",
                        py: "0.66rem",
                        ...(isSmallScreen && { mt: 4 }),
                    }}
                >
                    START&nbsp;
                    <HomeIcon sx={{ fontSize: "2.5rem" }} />
                </Button>
            </Box>
        </>

    )
}

export default EndContentBox



