import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { ActivePage } from "./Models/ActivePageType"
import { QuestionTypes } from "./Models/QuestionTypes"
import { AnswersType } from "./Models/AnswersType"
import { evaluationStageType } from "./Models/EvaluationStageType"

import EvaluationStart from "./Pages/EvaluationStart"
/* import EvaluationChooseType from "./Pages/EvaluationChooseType" */
import EvaluationQuestion from "./Pages/EvaluationQuestion"
import EvaluationEnd from "./Pages/EvaluationEnd"
import MainLayout from "./Layouts/MainLayout"
import { ThemeProvider } from "@mui/material/styles"
import evaluationToolTheme from "./Styles/EvaluationToolTheme"

//Main Component for Evaluation Tool.

//Handles logic for tracking and cycling through pages, collecting answers, and rendering page elements.
const EvaluationTool = () => {
    const [activePage, setActivePage] = useState<ActivePage>("start")
    const [questionType, setQuestionType] = useState<QuestionTypes>(null)

    //Keeps track of what type of evaluation is going.
    const [searchParams] = useSearchParams()
    const evaluationStage = searchParams.get("stage") as evaluationStageType

    //This is used for collecting all the answers.
    const [collectedAnswers, setCollectedAnswers] = useState<AnswersType>({})

    //This is used storing answers when looping a single question
    const [collectedAnswersCopy, setCollectedAnswersCopy] = useState<AnswersType>({})

    //Remove these in final build, this is for debugging only.
    useEffect(() => {
        console.log("CollectedAnswersUpdated: ", collectedAnswers)
    }, [collectedAnswers])
    useEffect(() => {
        console.log("CollectedAnswersCopyUpdated: ", collectedAnswersCopy)
    }, [collectedAnswersCopy])

    const setPageToStart = () => setActivePage("start")
    /*     const setPageToChooseType = () => setActivePage("chooseType") */
    const setPageToQuestionX = (qNum: number) => setActivePage(`question${qNum}`)
    const setPageToEnd = () => setActivePage("end")

    //This function decides which page will be rendered.
    const renderPage = (activePage: ActivePage) => {
        //PROPS
        const evaluationStartProps = {
            evaluationStage,
            setPageToQuestionX,
            activePage,
            collectedAnswersCopy,
            setCollectedAnswers,
            setPageToStart,
            collectedAnswers,
        }
        const evaluationQuestionProps = {
            activePage,
            collectedAnswers,
            setPageToStart,
            setPageToQuestionX,
            setPageToEnd,
            setCollectedAnswers,
            setQuestionType,
            evaluationStage,
            setCollectedAnswersCopy,
            collectedAnswersCopy,
        };
        const evaluationEndProps = {
            setPageToStart,
            setPageToQuestionX,
            setCollectedAnswers,
            evaluationStage,
            collectedAnswers,
            setCollectedAnswersCopy,
            collectedAnswersCopy,
            activePage,
        }

        switch (true) {
            case activePage === "start":
                return <EvaluationStart {...evaluationStartProps} />
            /*             case activePage === "chooseType":
                            return <EvaluationChooseType setPageToStart={setPageToStart} setPageToQuestionX={setPageToQuestionX} setQuestionType={setQuestionType} questionType={questionType} /> */
            case /^question\d+$/.test(activePage):
                return <EvaluationQuestion {...evaluationQuestionProps} />

            case activePage === "end":
                return <EvaluationEnd {...evaluationEndProps} />
        }
    }

    return (
        <ThemeProvider theme={evaluationToolTheme}>
            <MainLayout>{renderPage(activePage)}</MainLayout>
        </ThemeProvider>
    )
}

export default EvaluationTool
