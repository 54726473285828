import React from "react"

import StartContentBox from "../Components/StartContentBox"

import ImageUtvärdering from "../Assets/Images/Bildstöd/utvärdering.svg"
import ImageBörja from "../Assets/Images/Bildstöd2/börja.svg"
import { evaluationStageType } from "../Models/EvaluationStageType"

import { startButtonText } from "../Utilities/EvaluationStageVariables"
import BackForwardButtonBox from "../Components/BackForwardButtonBox"
import { ActivePage } from "../Models/ActivePageType"
import { AnswersType } from "../Models/AnswersType"


interface EvaluationStartProps {
    evaluationStage: evaluationStageType;
    setPageToQuestionX: (qNum: number) => void;
    activePage: ActivePage;
    collectedAnswersCopy: AnswersType;
    setCollectedAnswers: React.Dispatch<React.SetStateAction<AnswersType>>;
    collectedAnswers: AnswersType;
}

//Start Page of the Evaluation Tool
const EvaluationStart = ({ evaluationStage, setPageToQuestionX, activePage, collectedAnswersCopy, setCollectedAnswers, collectedAnswers }: EvaluationStartProps) => {
    let buttonText = startButtonText(evaluationStage);

    const backForwardButtonProps = {
        makeVisible: false,
        pageForward: () => { },
        pageBackward: () => { },
        activePage,
        collectedAnswersCopy,
        evaluationStage,
        setCollectedAnswers,
        collectedAnswers,
    };

    return (
        <>
            <BackForwardButtonBox {...backForwardButtonProps} />
            <StartContentBox
                imageA={ImageBörja}
                /*             imageB={ImageUtvärdering} */
                imageAltA="börja"
                /*             imageAltB="utvärdering" */
                buttonText={buttonText}
                buttonAction={() => { setPageToQuestionX(1) }}
            />
        </>
    )
}

export default EvaluationStart
