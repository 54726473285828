import { Typography } from "@mui/material"
import { setQuestion } from "../Data/questions"
import { evaluationStageType } from "../Models/EvaluationStageType"

interface QuestionProps {
    question: number | string;
    evaluationStage: evaluationStageType;
}

const Question: React.FC<QuestionProps> = ({ question, evaluationStage }) => {
    return (
        <Typography variant="h1"
            sx={{
                border: "solid 3px #41b771",
                borderRadius: "40px",
                py: 8,
                px: 5,
                width: "95%",
                textAlign: "center",
            }}>{setQuestion(question, evaluationStage)}
        </Typography >
    )
}

export default Question
