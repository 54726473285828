import React from "react"
import { useEffect } from "react"
import { ROUTES } from '../../../utils/api'

import { Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material"

import { useNavigate } from "react-router-dom"
import { AnswersType } from "../Models/AnswersType"
import { useCurrentUser } from "../../../hooks/contexts/currentUserContext"
import { saveEvaluation } from "../../../firebase/databaseApi"
import { evaluationStageType } from "../Models/EvaluationStageType"

import { flexCenterC } from "../Styles/EvaluationToolTheme"

interface EndModalProps {
    endAppClicked: boolean
    setEndAppClicked: (exit: boolean) => void
    collectedAnswersCopy: AnswersType
    evaluationStage: evaluationStageType
    collectedAnswers: AnswersType
}

//This modal is for confirming abortion of current evaluation, and exiting back to main app.
const EndModal: React.FC<EndModalProps> = ({ endAppClicked, setEndAppClicked, collectedAnswersCopy, evaluationStage, collectedAnswers }) => {
    const currentUser = useCurrentUser()
    const customerId = currentUser?.customer?.id
    //Modal Logic
    const [openModal, setOpenModal] = React.useState(false)
    const handleCloseModal = () => {
        setOpenModal(false)
        setEndAppClicked(false)
    }
    useEffect(() => {
        if (endAppClicked) {
            setOpenModal(true)
        }
    }, [endAppClicked])

    const navigate = useNavigate()

    const answerCount = Object.keys(collectedAnswersCopy).length

    const handleEndEvaluation = () => {
        if (customerId) {
            let dataToSend: AnswersType
            if (evaluationStage === "full") {
                dataToSend = collectedAnswers
            } else {
                dataToSend = collectedAnswersCopy
            }

            saveEvaluation(dataToSend, customerId, evaluationStage)
            navigate(ROUTES.HOME)

            alert("Answers Sent To Database"); //Temp for demo purposes
        }
    }

    return (
        <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth={false} PaperProps={{ sx: { padding: "3rem" } }}>
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h1" sx={{
                    border: "solid 3px #41b771",
                    borderRadius: "40px",
                    py: 8,
                    px: 5,
                    width: "100%",
                    textAlign: "center",
                }}>
                    {`VILL DU AVSLUTA UTVÄRDERINGSVERKTYGET?`}
                </Typography>
            </DialogTitle>
            <DialogActions sx={{ alignItems: "center", justifyContent: "center", mt: "1rem" }}>
                <Button onClick={handleCloseModal} fullWidth variant="outlined" color="secondary" sx={{ mx: "3rem", fontSize: "2.5rem" }}>
                    NEJ
                </Button>
                <Button onClick={handleEndEvaluation} fullWidth variant="contained" color="error" sx={{ mx: "3rem", fontSize: "2.5rem" }}>
                    JA
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default EndModal

/* const answerCount = collectedAnswersCopy.length;
    < Typography variant = "h1" >{`Vill du avsluta och spara ${answerCount} svar?`}</ >
        < Button onClick={handleCloseModal} variant="contained" color="secondary" sx={{ mx: "3rem" }}> NEJ < Button />
            <Button onClick={ } variant="contained" color="error" sx={{ mx: "3rem" }}>JA</Button> */
