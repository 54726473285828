import { createTheme, Theme } from "@mui/material/styles"

const evaluationToolTheme = createTheme({
    typography: {
        fontFamily: "'Raleway', 'sans-serif'",
        h1: {
            fontSize: "2.5rem"
        },
        h2: {
            fontSize: "2rem"
        }
    },

    palette: {
        primary: {
            main: "#41b771"
        }
    },

    components: {
        MuiButton: {
            //Cant figure out how to set MUI Icon styles other than globally for all icons,
            //Handled in SX props for now.
            styleOverrides: {
                root: {
                    color: "black"
                },
                containedPrimary: (button) => ({
                    backgroundColor: button.theme.palette.primary.main,
                    fontSize: "2.5rem"
                }),
                containedSecondary: (button) => ({
                    backgroundColor: button.theme.palette.primary.main,
                    fontSize: "1.75rem",
                    "&:hover": {
                        backgroundColor: button.theme.palette.primary.dark // Darker green for hover
                    }
                }),
                containedError: (button) => ({
                    backgroundColor: "e95f28",
                    fontSize: "1.75rem"
                }),
                outlined: {
                    fontSize: "1.75rem",
                    borderColor: "black",
                    ":hover": {
                        borderColor: "black",
                        backgroundColor: "rgba(0, 0, 0, 0.04)"
                    }
                }
            }
        }
    }
})

export default evaluationToolTheme

export const flexCenterC = {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex"
}

export const flexSpaceC = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between"
}

export const flexCenterR = {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    display: "flex"
}

export const flexSpaceR = {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    display: "flex"
}
