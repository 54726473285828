import { Box } from "@mui/material"

import { AnswersType } from "../Models/AnswersType"
import { AnswerNumber } from "../Models/AnswerNumberType"

import AnswerSelectCard from "./AnswerSelectCard"

import { flexSpaceR, flexSpaceC } from "../Styles/EvaluationToolTheme"
import { evaluationStageType } from "../Models/EvaluationStageType"

import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen"

const AnswerSelect = ({
    answerNumber,
    collectedAnswers,
    setCollectedAnswers,
    evaluationStage,
}: {
    answerNumber: AnswerNumber
    collectedAnswers: AnswersType
    setCollectedAnswers: React.Dispatch<React.SetStateAction<AnswersType>>
    evaluationStage: evaluationStageType
}) => {
    const isSmallScreen = useIsSmallScreen();

    //PROPS
    const answerSelectCardProps = {
        answerNumber,
        collectedAnswers,
        setCollectedAnswers,
        evaluationStage,
    };

    return (
        <Box
            sx={{
                width: "90%",
                height: "100%",
                ...(isSmallScreen ? flexSpaceC : flexSpaceR),
                flexGrow: 0,
                overFlow: "hidden",
                mt: 4,
            }}
        >
            <AnswerSelectCard {...answerSelectCardProps} index={0} />
            <AnswerSelectCard {...answerSelectCardProps} index={1} />
            <AnswerSelectCard {...answerSelectCardProps} index={2} />
        </Box >
    )
}

export default AnswerSelect
