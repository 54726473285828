import { Box, Button, Typography } from "@mui/material"
import { useEffect, useRef } from "react";
import React from "react";
import { WithChildren } from "../Models/WithChildren"
import EndCurrentModal from "./EndCurrentModal";
import EndModal from "./EndModal";

import { ActivePage } from "../Models/ActivePageType";

import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { flexSpaceC, flexSpaceR } from "../Styles/EvaluationToolTheme"
import { AnswersType } from "../Models/AnswersType";
import { evaluationStageType } from "../Models/EvaluationStageType";

interface BackForwardButtonBoxProps extends WithChildren {
    pageForward: () => void;
    pageBackward: () => void;
    makeVisible: boolean;
    activePage: string;
    collectedAnswersCopy: AnswersType;
    evaluationStage: evaluationStageType;
    setCollectedAnswers: React.Dispatch<React.SetStateAction<AnswersType>>;
    setPageToStart?: () => void;
    collectedAnswers: AnswersType;
}

const BackForwardButtonBox: React.FC<BackForwardButtonBoxProps> = ({
    children,
    pageForward,
    pageBackward,
    makeVisible,
    activePage,
    collectedAnswersCopy,
    evaluationStage,
    setCollectedAnswers,
    setPageToStart,
    collectedAnswers,
}) => {
    const visibility = makeVisible === false ? "hidden" : "visible"

    const [endCurrentClicked, setEndCurrentClicked] = React.useState(false);
    const [endAppClicked, setEndAppClicked] = React.useState(false)

    useEffect(() => {
        console.log(endAppClicked);
    }, [endAppClicked])

    const currentEvaluationIndex = () => {
        const value = Object.keys(collectedAnswersCopy).length;

        if (collectedAnswersCopy) {
            if (activePage === "start") { return value }
            else if (activePage === "end") { return value }
            else if (activePage.startsWith("question")) { return value + 1 }
        } else {
            return 1
        }
    }

    const endCurrentModalCondition = (activePage.startsWith("question") && setPageToStart && collectedAnswersCopy);
    const endModalStartCondition = (activePage === "start");
    const endModalEndCondition = (activePage === "end");

    const handleXBtnClick = () => {
        if (endCurrentModalCondition) {
            setEndCurrentClicked(true);

        } else if (endModalStartCondition) {
            setEndAppClicked(true);

        } else if (endModalEndCondition) {
            setEndAppClicked(true);
        }
    }

    return (
        <>
            <Box sx={{ width: "100%", ...flexSpaceR, height: "3rem" }}>
                <Box position="relative" display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ aspectRatio: 1 / 1, border: "solid 2px black", borderRadius: "5px", height: "75%", fontWeight: 600, opacity: "25%", textAlign: "center", lineHeight: "1.2" }}>{currentEvaluationIndex()}</Box>
                <Button onClick={handleXBtnClick} variant="outlined" sx={{
                    padding: 0,
                    minWidth: "auto",
                    opacity: "25%",
                    borderRadius: "2px",
                    ":hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        color: "rgba(0, 0, 0, 0.75)",
                    },
                }}><CloseIcon sx={{ fontSize: "2.5rem" }} /></Button>
            </Box>
            {endCurrentModalCondition && <EndCurrentModal endCurrentClicked={endCurrentClicked} setEndCurrentClicked={setEndCurrentClicked} setCollectedAnswers={setCollectedAnswers} setPageToStart={setPageToStart} collectedAnswersCopy={collectedAnswersCopy} />}
            {endModalStartCondition && <EndModal endAppClicked={endAppClicked} setEndAppClicked={setEndAppClicked} collectedAnswersCopy={collectedAnswersCopy} evaluationStage={evaluationStage} collectedAnswers={collectedAnswers} />}
            {endModalEndCondition && <EndModal endAppClicked={endAppClicked} setEndAppClicked={setEndAppClicked} collectedAnswersCopy={collectedAnswersCopy} evaluationStage={evaluationStage} collectedAnswers={collectedAnswers} />}
        </>
    )
}

export default BackForwardButtonBox



{/* > */ }
