import EndContentBox from "../Components/EndContentBox"
import BackForwardButtonBox from "../Components/BackForwardButtonBox"

import { AnswersType } from "../Models/AnswersType"

import ImageTack from "../Assets/Images/Bildstöd/tack.svg"
import ImageBorja from "../Assets/Images/Bildstöd/börja.svg"
import ImageDone from "../Assets/Images/Bildstöd2/färdigt.svg"

import { evaluationStageType } from "../Models/EvaluationStageType"
import { determineQuestionsArr } from "../Utilities/EvaluationStageVariables"
import { deleteLastAnswer } from "../Utilities/UpdateCollectedAnswersCopy"
import { ActivePage } from "../Models/ActivePageType"

interface EvaluationEndProps {
    setPageToStart: () => void;
    setPageToQuestionX: (qNum: number) => void;
    setCollectedAnswers: React.Dispatch<React.SetStateAction<AnswersType>>;
    evaluationStage: evaluationStageType;
    collectedAnswers: AnswersType;
    setCollectedAnswersCopy: React.Dispatch<React.SetStateAction<AnswersType>>;
    collectedAnswersCopy: AnswersType;
    activePage: ActivePage;
}

const EvaluationEnd: React.FC<EvaluationEndProps> = ({
    setPageToStart,
    setPageToQuestionX,
    setCollectedAnswers,
    evaluationStage,
    collectedAnswers,
    setCollectedAnswersCopy,
    collectedAnswersCopy,
    activePage
}) => {
    const questionsArr = determineQuestionsArr(evaluationStage);

    const pageAnswerIndex: number = questionsArr.length;

    //PROPS
    const backForwardButtonBoxProps = {
        pageForward: () => { },
        pageBackward: () => {
            setPageToQuestionX(pageAnswerIndex);
            if (evaluationStage === "before" || evaluationStage === "after") {
                deleteLastAnswer(setCollectedAnswersCopy);
            }
        },
        makeVisible: false,
        activePage: activePage,
        setCollectedAnswers: setCollectedAnswers,
        evaluationStage: evaluationStage,
        collectedAnswers: collectedAnswers,
        collectedAnswersCopy: collectedAnswersCopy,
    };

    const endContentBoxProps = {
        imageA: ImageDone,
        imageAltA: "klar",
        buttonText: "NU ÄR DET SLUT, TACK FÖR IDAG",
        buttonAction: () => { },
        evaluationStage: evaluationStage,
        setPageToStart: setPageToStart,
        collectedAnswers: collectedAnswers,
        setCollectedAnswersCopy: setCollectedAnswersCopy,
        setCollectedAnswers: setCollectedAnswers,
        collectedAnswersCopy: collectedAnswersCopy,
        setPageToQuestionX: () => setPageToQuestionX(pageAnswerIndex)
    };

    return (
        <>
            {collectedAnswersCopy && <BackForwardButtonBox {...backForwardButtonBoxProps} />}
            <EndContentBox {...endContentBoxProps} />
        </>
    )
}


export default EvaluationEnd
